<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="pc indexLayoutContent" style="padding: 0px">
          <div
            class="page pcenter flex-col justify-start"
            style="height: auto; background-color: #fff"
          >
            <top-a
              :kind="``"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
            ></top-a>

            <div class="group_pcenter flex-col">
              <div class="baseinfo" v-if="!editPinfo && !editLinfo">
                <div class="pline">
                  <div
                    class="photo"
                    v-if="imgurl == ''"
                    :style="`background-image:url(${'https://cdn.moheweb.com/vdb/lweb/imgs/person.png'});`"
                  ></div>
                  <div
                    class="photo"
                    v-else
                    :style="`background-image:url(${baseUrl + imgurl});`"
                  ></div>
                </div>
                <div class="pline">
                  <div class="welcome">
                    欢迎来到趣野无限，
                    <!-- Friend -->
                    {{ memberdata.member_nickName }}
                    <div v-if="memberdata.member_auth == '1'" class="renzheng">
                      <img
                        src="../assets/limgs/right.png"
                        style="width: 24px"
                      /><span style="margin-left: 5px">已认证</span>
                    </div>
                  </div>
                </div>
                <div class="pline">
                  <div class="btns">
                    <div
                      v-if="memberdata.member_auth == '0'"
                      class="btn btnf"
                      @click="onIdCardAuth"
                    >
                      实名认证
                    </div>

                    <div class="btn" @click="openeditpinfo">编辑个人信息</div>
                    <div class="btn" @click="openeditlinfo">编辑兴趣</div>
                    <div class="btn" @click="clearpinfo">退出</div>
                  </div>
                </div>
                <!-- <div class="pline" style="margin-top: 20px">
                  <div class="btns">
                  </div>
                </div> -->

                <div class="pline" style="flex-direction: column">
                  <div class="tabs">
                    <div
                      :class="`tab ${tabselect == 'show' ? 'tabselected' : ''}`"
                      @click="
                        () => {
                          showtabdiv('show');
                        }
                      "
                    >
                      我的SHOW
                    </div>
                    <div
                      :class="`tab ${tabselect == 'club' ? 'tabselected' : ''}`"
                      @click="
                        () => {
                          showtabdiv('club');
                        }
                      "
                    >
                      我的CLUB
                    </div>
                    <div
                      :class="`tab ${tabselect == 'buy' ? 'tabselected' : ''}`"
                      @click="
                        () => {
                          showtabdiv('buy');
                        }
                      "
                    >
                      我的购物
                    </div>
                    <div
                      :class="`tab ${
                        tabselect == 'jifen' ? 'tabselected' : ''
                      }`"
                      @click="
                        () => {
                          showtabdiv('jifen');
                        }
                      "
                    >
                      我的积分
                    </div>
                  </div>
                  <div class="tabdiv" v-if="tabselect == 'show'">
                    <div class="clists" v-if="clist33.length>0">
                      <template v-for="(item, index) in clist33">
                        <div class="clitem" :key="index">
                          <div
                            class="img"
                            :style="`background-image:url(${
                              baseUrl + item.imgurl
                            })`"
                            @click="
                              () => {
                                godetall(
                                  item.url,
                                  item.pname,
                                  item.kind,
                                  item.id
                                );
                              }
                            "
                          ></div>
                          <div
                            class="info"
                            @click="
                              () => {
                                godetall(
                                  item.url,
                                  item.pname,
                                  item.kind,
                                  item.id
                                );
                              }
                            "
                          >
                            <div class="title" v-html="item.pname"></div>
                            <div class="tip">
                              <div>
                                时间：{{ item.startdate }}-{{ item.finaldate }}
                              </div>
                              <div>地点：{{ item.adr }}</div>
                            </div>
                          </div>
                          <div class="action">
                            <div
                              class="btn"
                              @click="
                                () => {
                                  getShowQr(item, item.kind, item.id);
                                }
                              "
                            >
                              参会凭证
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div v-else style="text-algin:left; width:100%; font-size:18px; color:#999; padding-top:30px; padding-bottom:30px; font-weight:600; ">
                      <i>没有报名记录</i>
                    </div>
                  </div>
                  <div class="tabdiv" v-if="tabselect == 'club'">
                    <div class="clists" v-if="clist40.length>0">
                      <template v-for="(item, index) in clist40">
                        <div class="clitem" :key="index">
                          <div
                            class="img"
                            :style="`background-image:url(${
                              baseUrl + item.imgurl
                            })`"
                            @click="
                              () => {
                                godetall(
                                  item.url,
                                  item.pname,
                                  item.kind,
                                  item.id
                                );
                              }
                            "
                          ></div>
                          <div
                            class="info"
                            @click="
                              () => {
                                godetall(
                                  item.url,
                                  item.pname,
                                  item.kind,
                                  item.id
                                );
                              }
                            "
                          >
                            <div class="title" v-html="item.pname"></div>
                            <div class="tip">
                              <div>时间：{{ item.ccdata.datetime }}</div>
                              <div>地点：{{ item.adr }}</div>
                            </div>
                          </div>
                          <div class="action">
                            <template v-if="item.slist.pay == '1'">
                              <template v-if="item.slist.over == '0'">
                                <template v-if="parseInt(item.state) == 2">
                                </template>

                                <div
                                  v-if="item.slist.cancel == 0"
                                  class="btn"
                                  @click="
                                    () => {
                                      getShowQr(item, item.kind, item.id);
                                    }
                                  "
                                >
                                  参会凭证
                                </div>
                                <template v-if="parseInt(item.state) < 2">
                                  <div
                                    v-if="
                                      item.slist.cancel == 0 &&
                                      item.slist.refund_status == ''
                                    "
                                    class="btn btn0"
                                    @click="
                                      () => {
                                        cancelbaoming(item.slist.out_trade_no);
                                      }
                                    "
                                  >
                                    撤销报名
                                  </div>
                                  <div
                                    v-if="
                                      item.slist.cancel == 1 &&
                                      item.slist.refund_status == 'SUCCESS'
                                    "
                                    class="btn"
                                    style="
                                      color: #999;
                                      background: none;
                                      border-color: #999;
                                    "
                                  >
                                    撤销成功
                                  </div>
                                  <div
                                    v-if="
                                      item.slist.cancel == 1 &&
                                      item.slist.refund_status == 'PROCESSING'
                                    "
                                    class="btn"
                                  >
                                    撤销中...
                                  </div>
                                </template>
                              </template>
                              <template v-if="item.slist.over == '1'">
                                <div
                                  v-if="item.slist.refund_status == ''"
                                  class="btn"
                                  @click="
                                    () => {
                                      tuikuan(item.slist.out_trade_no);
                                    }
                                  "
                                >
                                  超时退款{{ item.slist.refund_status }}
                                </div>
                                <div
                                  v-if="item.slist.refund_status == 'SUCCESS'"
                                  class="btn"
                                  style="
                                    color: #999;
                                    background: none;
                                    border-color: #999;
                                  "
                                >
                                  已退款
                                </div>
                                <div
                                  v-if="
                                    item.slist.refund_status == 'PROCESSING'
                                  "
                                  class="btn"
                                  style="
                                    color: #008109;
                                    background: none;
                                    border-color: #008109;
                                  "
                                >
                                  处理中...
                                </div>
                              </template>
                            </template>
                            <template v-if="item.slist.pay == '0'">
                              <div
                                v-if="item.slist.over == '0'"
                                class="btn"
                                @click="showpayqr(item.slist.out_trade_no)"
                                style="
                                  color: rgba(239, 123, 73, 1);
                                  background: none;
                                "
                              >
                                现在支付
                              </div>
                              <div
                                v-if="item.slist.over == '1'"
                                class="btn"
                                style="
                                  color: #999;
                                  background: none;
                                  border-color: #999;
                                "
                              >
                                超时
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div v-else style="text-algin:left; width:100%; font-size:18px; color:#999; padding-top:30px; padding-bottom:30px; font-weight:600; ">
                      <i>没有报名记录</i>
                    </div>
                  </div>
                  <div class="tabdiv" v-if="tabselect == 'buy'">
                    <div class="clists" v-if="full_order_info_list.length>0">
                      <template v-for="(item, index) in full_order_info_list">
                        <div class="clitemt" :key="index">
                          <template
                            v-for="(itemd, indexd) in item.full_order_info
                              .orders"
                          >
                            <div class="clitem" :key="indexd">
                              <div
                                class="img"
                                :style="`background-image:url(${itemd.pic_path}); background-size: contain;`"
                              ></div>
                              <div class="info">
                                <div
                                  class="title"
                                  style="font-size: 2.4rem"
                                  v-html="itemd.title"
                                ></div>
                                <div class="tip">
                                  <div>价格：{{ itemd.total_fee }} ￥</div>
                                  <div>
                                    订单状态：
                                    <span
                                      :class="`status_${item.full_order_info.order_info.status}`"
                                    >
                                      {{
                                        item.full_order_info.order_info
                                          .status_str
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="action">
                                <a :href="itemd.goods_url" target="_blank">
                                  <div class="btnsd">查看详情</div>
                                </a>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>

                    <div v-else style="text-algin:left; width:100%; font-size:18px; color:#999; padding-top:30px; padding-bottom:30px; font-weight:600; ">
                      <i>没有购买记录</i>
                    </div>

                  </div>
                  <div class="tabdiv" v-if="tabselect == 'jifen'">
                    <div class="jfinfo">
                      <label>当前积分：</label>
                      <span class="jf">{{ memberdata.member_jf }}</span>
                        <a style="margin-left:50px;" @click="openjflist">查看积分记录</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="lr" v-if="editPinfo">
                <div class="title">
                  编辑个人信息
                  <div class="closeicon" @click="closeloginreg"></div>
                </div>

                <div class="ildiv" style="margin-left: -5%">
                  <div class="inputList">
                    <a-upload
                      action=""
                      list-type="picture-card"
                      :show-upload-list="false"
                      :before-upload="
                        (file) => {
                          beforeImgUpload(file);
                        }
                      "
                    >
                      <img
                        v-if="imgurl"
                        class="tmimage"
                        :src="baseUrl + imgurl"
                        alt="avatar"
                      />
                      <div
                        v-else
                        style="
                          background-image: url(https://cdn.moheweb.com/vdb/lweb/imgs/person.png);
                          background-size: cover;
                          background-repeat: no-repeat;
                          background-position: center;
                          height: 100%;

                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <a-icon :type="uploading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">上传头像</div>
                      </div>
                    </a-upload>
                  </div>

                  <div class="inputList">
                    <template v-for="(item, index) in inpbasedate">
                      <template v-if="!item.del">
                        <div class="inputItem" :key="index">
                          <div class="line lt">
                            <span class="redx" v-if="item.required">*</span>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-if="lag == 1"
                              v-html="item.name"
                            ></label>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-else
                              v-html="item.cname"
                            ></label>
                          </div>
                          <div class="line lp">
                            <template v-if="item.kind == 'input'">
                              <template
                                v-if="
                                  memberdata.member_auth == '1' &&
                                  (inpbasedate[index].code == 'name' ||
                                    inpbasedate[index].code == 'idnumber')
                                "
                              >
                                <span
                                  style="
                                    line-height: 42px;
                                    font-size: 16px;
                                    color: #999;
                                  "
                                  >{{ inpbasedate[index].value }}</span
                                >
                              </template>
                              <template v-else>
                                <a-input
                                  v-if="lag == 1"
                                  class="input"
                                  v-model="inpbasedate[index].value"
                                  :placeholder="item.placeholder"
                                />
                                <a-input
                                  v-else
                                  class="input"
                                  v-model="inpbasedate[index].value"
                                  :placeholder="item.cplaceholder"
                                />
                              </template>
                            </template>
                            <template v-if="item.kind == 'select'">
                              <template
                                v-if="
                                  memberdata.member_auth == '1' &&
                                  inpbasedate[index].code == 'idtype'
                                "
                              >
                                <span
                                  style="
                                    line-height: 42px;
                                    font-size: 16px;
                                    color: #999;
                                  "
                                  >身份证</span
                                >
                              </template>
                              <template v-else>
                                <template v-if="lag == '1'">
                                  <a-select
                                    v-model="inpbasedate[index].value"
                                    class="select"
                                    @change="handleSelectChange"
                                  >
                                    <a-select-option key=""
                                      ><span class="lightoption">{{
                                        item.placeholder
                                      }}</span></a-select-option
                                    >
                                    <a-select-option
                                      v-for="item2 in item.values"
                                      :key="item2.id"
                                    >
                                      {{ item2.name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-else>
                                  <a-select
                                    v-model="inpbasedate[index].value"
                                    class="select"
                                    @change="handleSelectChange"
                                  >
                                    <a-select-option key=""
                                      ><span class="lightoption">{{
                                        item.cplaceholder
                                      }}</span></a-select-option
                                    >
                                    <a-select-option
                                      v-for="item2 in item.values"
                                      :key="item2.id"
                                    >
                                      {{ item2.cname }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                              </template>

                              <!-- <template v-if="lag == '1'">
                              <a-select
                                v-model="inpbasedate[index].value"
                                class="select"
                                @change="handleSelectChange"
                              >
                                <a-select-option key=""
                                  ><span class="lightoption">{{
                                    item.placeholder
                                  }}</span></a-select-option
                                >
                                <a-select-option
                                  v-for="item2 in item.values"
                                  :key="item2.id"
                                >
                                  {{ item2.name }}
                                </a-select-option>
                              </a-select>
                            </template>
                            <template v-else>
                              <a-select
                                v-model="inpbasedate[index].value"
                                class="select"
                                @change="handleSelectChange"
                              >
                                <a-select-option key=""
                                  ><span class="lightoption">{{
                                    item.cplaceholder
                                  }}</span></a-select-option
                                >
                                <a-select-option
                                  v-for="item2 in item.values"
                                  :key="item2.id"
                                >
                                  {{ item2.cname }}
                                </a-select-option>
                              </a-select>
                            </template> -->
                            </template>

                            <template v-if="item.kind == 'selectcountry'">
                              <template v-if="lag == '1'">
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="
                                    () => {
                                      handleCountrySelectChange(index);
                                    }
                                  "
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.placeholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="
                                    () => {
                                      handleCountrySelectChange(index);
                                    }
                                  "
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.cname }}
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>

                            <template v-if="item.kind == 'selectPC'">
                              <template v-if="lag == '1'">
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.placeholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    {{ item2.name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <a-select
                                  v-model="inpbasedate[index].value"
                                  class="select"
                                  @change="handleSelectChange"
                                >
                                  <a-select-option key=""
                                    ><span class="lightoption">{{
                                      item.cplaceholder
                                    }}</span></a-select-option
                                  >
                                  <a-select-option
                                    v-for="item2 in item.values"
                                    :key="item2.id"
                                  >
                                    <template v-if="item2.cname != ''">
                                      {{ item2.cname }}
                                    </template>
                                    <template v-else>
                                      {{ item2.name }}
                                    </template>
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>

                            <template v-if="item.kind == 'date'">
                              <a-date-picker
                                :default-value="moment(item.value, dateFormat)"
                                :format="dateFormat"
                                @change="onBirthdayChange"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="line" style="justify-content: center">
                    <a-button
                      class="submitPinfo"
                      @click="
                        () => {
                          submitPinfo();
                        }
                      "
                    >
                      保存
                    </a-button>
                  </div>
                </div>
              </div>

              <div class="lr" v-if="editLinfo">
                <div class="title">
                  <img
                    src="../assets/limgs/r2.png"
                    style="width:207px height:28px;"
                  />
                  <div class="closeicon" @click="closeloginreg"></div>
                </div>

                <div class="ildiv" style="margin-left: -5%">
                  <div class="inputList">
                    <template
                      v-for="(item, index) in inpLikedate.filter((item) => {
                        return item.del == false;
                      })"
                    >
                      <template v-if="!item.del">
                        <div
                          class="inputItem"
                          :key="index"
                          style="width: 99%; border: none"
                        >
                          <div class="line lt">
                            <span class="redx" v-if="item.required">*</span>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-if="lag == 1"
                              v-html="index + 1 + '.' + item.name"
                            ></label>
                            <label
                              :class="`t ${
                                checkshow == 1 &&
                                item.value == '' &&
                                item.required
                                  ? 'checkit'
                                  : ''
                              } `"
                              v-else
                              v-html="index + 1 + '.' + item.cname"
                            ></label>
                          </div>
                          <div class="line lb">
                            <template v-if="lag == 1">
                              <template v-for="(item2, index2) in item.values">
                                <div
                                  :class="`lbitem ${
                                    item2.selected ? 'lbselected' : ''
                                  }`"
                                  :key="index2"
                                  v-html="item2.name"
                                  @click="
                                    () => {
                                      clicklike(
                                        item.code,
                                        index2,
                                        item2.code,
                                        item.multiple
                                      );
                                    }
                                  "
                                ></div>
                              </template>
                            </template>
                            <template v-else>
                              <template v-for="(item2, index2) in item.values">
                                <div
                                  :class="`lbitem ${
                                    item2.selected ? 'lbselected' : ''
                                  }`"
                                  :key="index2"
                                  v-html="item2.cname"
                                  @click="
                                    () => {
                                      clicklike(
                                        item.code,
                                        index2,
                                        item2.code,
                                        item.multiple
                                      );
                                    }
                                  "
                                ></div>
                              </template>
                              <div class="other" style="color: #000">
                                <template
                                  v-if="
                                    item.values.filter((item3) => {
                                      return item3.code == '其他';
                                    }).length > 0
                                  "
                                >
                                  <template
                                    v-if="
                                      item.values.filter((item3) => {
                                        return item3.code == '其他';
                                      })[0].selected
                                    "
                                  >
                                    <label>其他:</label>
                                    <a-input
                                      class="input"
                                      v-model="inpLikedate[index].other"
                                      :placeholder="''"
                                      :maxLength="100"
                                    />
                                  </template>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="line" style="justify-content: center">
                    <a-button
                      class="submitPinfo"
                      @click="
                        () => {
                          submitLinfo();
                        }
                      "
                    >
                      保存
                    </a-button>
                  </div>
                </div>
              </div>
            </div>

            <footer-a :kind="``" :lag="lag"></footer-a>

            <div class="qrcode" v-if="showfkqr" @click="hideqrcode">
              <div class="ticketdiv" style="width: auto">
                <div class="qrdiv" @click="closepayqr">
                  <VueQrcode
                    :value="qrLink || qrContent"
                    :options="QrFkCodeOpt"
                    class="qr"
                    style="
                      margin: 0 auto;
                      height: 250px !important;
                      width: 250px !important;
                    "
                  ></VueQrcode>
                  <span
                    class="qtip"
                    style="font-size: 18px; line-height: 1; margin-top: 20px"
                    >微信扫码支付</span
                  >
                  <div class="closeicon" @click="closepayqr"></div>
                </div>
              </div>
            </div>

            <div class="qrcode" v-if="showqr" @click="hideqrcode">
              <div class="ticketdiv">
                <div class="infodiv">
                  <div class="logo"></div>
                  <div class="idnumtitle">{{ ticketdata.name }}</div>
                  <div class="idnumber">{{ ticketdata.idnumber }}</div>
                  <div class="cinfo">
                    <div class="ctitle" v-html="ticketdata.pname"></div>
                    <div class="cline"></div>

                    <template v-if="ticketdata.kind == '40'">
                      <div class="cct">场次: {{ ticketdata.ccdata.title }}</div>
                      <div class="cct">地址: {{ ticketdata.adr }}</div>
                    </template>
                    <template v-if="ticketdata.kind == '33'">
                      <div class="cct">
                        时间: {{ ticketdata.startdate }} -
                        {{ ticketdata.finaldate }}
                      </div>
                      <div class="cct">地址: {{ ticketdata.adr }}</div>
                    </template>
                  </div>
                  <div class="cno">{{ ticketdata.no }}</div>
                  <div class="qrmb"></div>
                </div>
                <div class="qrdiv">
                  <VueQrcode
                    :value="qrLink || qrContent"
                    :options="QrCodeOpt"
                    class="qr"
                    style="margin: 0 auto"
                  ></VueQrcode>
                  <span class="qtip" style="font-size: 18px; line-height: 1"
                    >请使用本人身份证刷证入场</span
                  >
                  <div class="closeicon" @click="hideqrcode"></div>
                </div>
              </div>
            </div>
          </div>

          <a-modal
            :title="`积分历史记录`"
            :visible="visibleAddModal3"
            :confirm-loading="confirmLoading3"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleJfAddOk3"
            @cancel="handleJfAddCancel3"
            :width="640"
            :footer="null"
          >
            <a-table
              :columns="jf_columns"
              :data-source="jflist"
              :pagination="false"
            >
              <span
                slot="indexText"
                class="nmitem"
                slot-scope="text, record, index"
                >{{ index + 1 }}</span
              >
              <span slot="jfshowText" class="nmitem" slot-scope="text,record">
                <span style="font-weight:500; font-size:14px;">
                <b v-if="record.action == 'plus'" style="color:green;">+ {{text}}</b>
                <b v-else style="color:red;">- {{text}}</b>
                
                </span>
              </span>
              <span slot="normalText" class="nmitem" slot-scope="text,record">
                <span style="font-weight:500; font-size:14px;">{{text}}</span>
              </span>
            </a-table>
          </a-modal>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchCase,
  fetchCaseOne,
  updateMember,
  uploadImageP,
  fetchMemberinfo,
  memberLogout,
  fetchMyCase,
  idCardAuth,
  fetchSign,
  overTuikuan,
  updateTuikuanStatus,
  cancelTuikuan,
  fetchJflist,
} from "../api/index";
import FooterA from "../components/FooterA.vue";
import TopA from "../components/TopA.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
import moment from "moment";

import VueQrcode from "@chenfengyuan/vue-qrcode";

const countries = require("../assets/json/countries.json");
const states = require("../assets/json/states.json");

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}


const jf_columns = [
  {
    title: "#",
    dataIndex: "id",
    width: 60,
    // fixed: "left",
    scopedSlots: { customRender: "indexText" },
  },
  {
    title: "事件",
    dataIndex: "des",
    // width: 300,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "积分",
    dataIndex: "jf",
    // width: 300,
    scopedSlots: { customRender: "jfshowText" },
  },
  {
    title: "时间",
    dataIndex: "addtime",
    width: 200,
    scopedSlots: { customRender: "normalText" },
  },
  // {
  //   title: "操作",
  //   key: "action",
  //   width: 160,
  //   scopedSlots: { customRender: "action" },
  // },
];

export default {
  name: "index",
  components: {
    // FooterIndex,
    VueQrcode,
    FooterA,
    TopA,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      jf_columns,

      isEmpty: 0,
      checkshow: 0,

      ticketdata: {},

      memberdata: {},

      showqr: 0,

      qrLink: "https://www.baidu.com/",
      qrContent: "123123123",
      QrCodeOpt: {
        errorCorrectionLevel: "H",
        width: 320,
        height: 320,
        quality: 0.3,
        margin: 1,
        // color: {
        //   dark: "#010599FF",
        //   light: "#FFBF60FF",
        // },
      },
      QrFkCodeOpt: {
        errorCorrectionLevel: "H",
        width: 250,
        height: 250,
        quality: 0.3,
        margin: 1,
        // color: {
        //   dark: "#010599FF",
        //   light: "#FFBF60FF",
        // },
      },

      clist33: [],
      clist40: [],
      loading: false,
      dateFormat: "YYYY/MM/DD",
      editPinfo: false,
      editLinfo: false,

      inpLikedate: [],
      inpbasedate: [],

      countries: countries["RECORDS"],
      states: states["RECORDS"],

      tabselect: "show",
      pagesize: 12,
      pageno: 0,
      total: 40,
      sbindex: "",
      data: {},
      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      id: "0",
      kind: "",
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper03: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,

      sectionindex: 0,
      sections: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: 68,
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      uploading: false,
      imgurl: "",
      imgary: [],
      showfkqr: 0,

      goods: [],
      full_order_info_list: [],
      visibleAddModal3: false,
      confirmLoading3: false,

      jflist:[],

    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let id = this.$route.params.id;

    let kind = this.$route.params.kind;

    let lag = this.$route.params.lag;

    if (id) {
      this.id = id;
    }
    if (kind) {
      this.kind = kind;
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    that.inpbasedate = global.inpbasedate;

    that.inpLikedate = global.inpLikedate;

    that.memberdata = global.getMemberinfo();

    that.fetchMemberinfo();

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    // global.setIndexDclass("");
    // global.setIndexscroll(0);
    that.handleScroll;
    that.resizepage();
    this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);
    window.onresize = () => {
      that.handleResize();
    };

    this.fetchClist();

    // this.fetchone();

    // setTimeout(() => {
    //   that.qrLink = '12332112313312312';
    // }, 4000);

    // global.resetMemberinfo();
  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    // let that = this;
  },
  methods: {
    moment,

    handleJfAddOk3() {
      this.visibleAddModal3 = false;
    },
    handleJfAddCancel3() {
      this.visibleAddModal3 = false;
    },
    openjflist() {
      const that = this;
      this.visibleAddModal3 = true;
      let params = {};
      fetchJflist(params).then((res) => {
        console.log(res);
        that.jflist = res.results.list;
      });
    },
    fetchSign(out_trade_no) {
      if (this.showfkqr == 0) {
        return false;
      }
      const that = this;
      let params = { no: out_trade_no };
      console.log("params:", params);
      fetchSign(params).then((res) => {
        console.log(res);
        that.signdata = res.results;
        // if (that.signdata.cid != "0") {
        //   that.total = that.signdata.cdata.price;
        // } else {
        //   that.total = that.signdata.adata.price;
        // }
        if (that.signdata.pay == "1") {
          // window.location.reload();
        } else {
          setTimeout(() => {
            that.fetchSign(out_trade_no);
          }, 3000);
        }
      });
    },

    showpayqr(out_trade_no) {
      let that = this;
      console.log(`https://www.all-fields.com/#/payed/${out_trade_no}/`);
      this.qrLink = `https://www.all-fields.com/#/payed/${out_trade_no}/`;
      setTimeout(() => {
        that.showfkqr = 1;
        that.fetchSign(out_trade_no);
      }, 300);
    },
    tuikuan(out_trade_no) {
      const that = this;
      if (!that.tuikuaning) {
        that.tuikuaning = true;
        console.log("tuikuan", out_trade_no);
        overTuikuan({ out_trade_no: out_trade_no }).then((res) => {
          console.log("overTuikuan:", res);
          if (res.status == "PROCESSING") {
            updateTuikuanStatus({
              out_trade_no: out_trade_no,
              status: res.status,
            }).then((res2) => {
              console.log("updateTuikuanStatus:", res2);
              that.fetchClist();
              that.tuikuaning = false;
            });
          } else if (res.status == "WRONG") {
            that.tuikuaning = false;
            const h = this.$createElement;
            this.$info({
              title: "注意",
              content: h("div", {}, [
                h("p", `退款处理中，请刷新页面确认状态!`),
              ]),
              onOk() {},
            });
          }
        });
      }
    },
    closepayqr() {
      this.showfkqr = 0;
    },

    onIdCardAuth() {
      const that = this;
      console.log(this.memberdata);
      let postdata = {
        cardNo: this.memberdata.member_idnumber,
        realName: encodeURIComponent(this.memberdata.member_name),
      };

      console.log("onIdCardAuth:", postdata);
      idCardAuth(postdata).then((res) => {
        console.log("idCardAuth:", res);

        let results = res.results;

        if (results.message == "0") {
          //短时间验证次数过多，请等待30分钟后再试

          const h = this.$createElement;
          this.$info({
            title: "注意",
            content: h("div", {}, [h("p", `请核实您的姓名以及身份证号码!`)]),
            onOk() {},
          });
        }
        if (results.message == "1") {
          global.setauth("1");
          that.memberdata = global.getMemberinfo();
        }
        if (results.message == "2") {
          //短时间验证次数过多，请等待30分钟后再试

          const h = this.$createElement;
          this.$info({
            title: "注意",
            content: h("div", {}, [
              h(
                "p",
                `短时间验证次数过多，请等待${parseInt(
                  results.authtime
                )}分钟后再试!`
              ),
            ]),
            onOk() {},
          });
        }
        if (results.message == "-1") {
          console.log("已经通过审核");
        }
      });
    },

    closeloginreg() {
      this.editPinfo = false;
      this.editLinfo = false;

      window.addEventListener("scroll", this.handleScroll, true);
    },
    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
        }
      }
    },

    fetchClist(params = { pagesize: 1000 }) {
      this.clist33 = [];
      this.clist40 = [];
      console.log("fetchClist");
      let that = this;
      fetchMyCase(params).then((res) => {
        console.log(res.results.list);
        console.log(res.results.list.length > 0);
        if (res.results.list.length > 0) {
          let clist = res.results.list;

          clist.map((item) => {
            if (item.kind == "33") {
              that.clist33.push(item);
            }
            if (item.kind == "40") {
              that.clist40.push(item);
            }
          });
          that.goods = res.results.goods;
          that.full_order_info_list = that.goods.full_order_info_list;
        }
        console.log("clist33:", that.clist33);
        console.log("clist40:", that.clist40);
      });
    },

    clicklike(code, index2, code2 = "", multiple) {
      let index = 0;
      this.inpLikedate.map((item, idx) => {
        if (item.code == code) {
          index = idx;
        }
      });
      if (multiple) {
        let selected = this.inpLikedate[index].values[index2].selected;
        this.inpLikedate[index].values[index2].selected = !selected;
        console.log(this.inpLikedate[index].values[index2].selected);
      } else {
        for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
          this.inpLikedate[index].values[i].selected = false;
        }
        this.inpLikedate[index].values[index2].selected = true;
      }

      let arry = [];
      for (let i = 0; i < this.inpLikedate[index].values.length; i++) {
        let sed = this.inpLikedate[index].values[i].selected;
        if (sed) {
          arry.push(this.inpLikedate[index].values[i].code);
        }
        if (this.inpLikedate[index].values[i].code == "其他") {
          if (this.inpLikedate[index].values[i].selected == false) {
            this.inpLikedate[index].other = "";
          }
        }
      }
      let value = arry.join(",");
      this.inpLikedate[index].value = value;
    },

    setinpbasedate() {
      let that = this;

      that.inpbasedate[0].value = that.memberdata.member_nickName;
      that.inpbasedate[1].value = that.memberdata.member_sex;
      that.inpbasedate[2].value = that.memberdata.member_birthday;
      that.inpbasedate[3].value = that.memberdata.member_country;
      that.inpbasedate[4].value = that.memberdata.member_PC;
      that.inpbasedate[5].value = that.memberdata.member_name;
      that.inpbasedate[6].value = that.memberdata.member_idtype;
      that.inpbasedate[7].value = that.memberdata.member_idnumber;
      that.inpbasedate[8].value = that.memberdata.member_industry;
      that.inpbasedate[9].value = that.memberdata.member_company;
      that.inpbasedate[10].value = that.memberdata.member_duties;
      that.inpbasedate[11].value = that.memberdata.member_as;
      that.inpbasedate[12].value = that.memberdata.member_years;

      let statesvalues = that.states.filter((item) => {
        return (
          parseInt(item["country_id"]) == parseInt(that.inpbasedate[3].value)
        );
      });

      that.inpbasedate[3].values = countries["RECORDS"];
      that.inpbasedate[4].values = statesvalues;

      console.log("inpbasedate:", that.inpbasedate);
    },

    fetchMemberinfo() {
      let that = this;
      let params = { tel: global.getMemberinfo().member_tel };
      console.log("params:", params);
      fetchMemberinfo(params).then((res) => {
        let message = res.results.message;
        if (message == 2) {
          that.yzmmsg = "登录过期";
          global.clearMemberInfo();
          that.$router.go(-1);
          return false;
        } else if (message == 3) {
          that.yzmmsg = "电话不存在";
          return false;
        } else if (message == 1) {
          let data = res.results;
          that.memberdata = res.results;

          global.setMemberInfo(data);

          that.inpbasedate[0].value = data["nickName"];
          that.inpbasedate[1].value = data["sex"];
          that.inpbasedate[2].value = data["birthday"];
          that.inpbasedate[3].value = data["country"];
          that.inpbasedate[4].value = data["PC"];
          that.inpbasedate[5].value = data["name"];
          that.inpbasedate[6].value = data["idtype"];
          that.inpbasedate[7].value = data["idnumber"];
          that.inpbasedate[8].value = data["industry"];
          that.inpbasedate[9].value = data["company"];
          that.inpbasedate[10].value = data["duties"];
          that.inpbasedate[11].value = data["as"];
          that.inpbasedate[12].value = data["years"];

          // console.log("that.inpbasedate:", that.inpbasedate);

          that.inpLikedate.map((item, index) => {
            if (item.code == "like0") {
              that.inpLikedate[index].value = that.memberdata["like0"];
              that.inpLikedate[index].other = that.memberdata["other0"];
            }
            if (item.code == "like1") {
              that.inpLikedate[index].value = that.memberdata["like1"];
              that.inpLikedate[index].other = that.memberdata["other1"];
            }
            if (item.code == "like2") {
              that.inpLikedate[index].value = that.memberdata["like2"];
              that.inpLikedate[index].other = that.memberdata["other2"];
            }
            if (item.code == "like3") {
              that.inpLikedate[index].value = that.memberdata["like3"];
              that.inpLikedate[index].other = that.memberdata["other3"];
            }
            if (item.code == "like4") {
              that.inpLikedate[index].value = that.memberdata["like4"];
            }
            if (item.code == "like5") {
              that.inpLikedate[index].value = that.memberdata["like5"];
            }
            if (item.code == "like6") {
              that.inpLikedate[index].value = that.memberdata["like6"];
              that.inpLikedate[index].other = that.memberdata["other6"];
            }
          });

          console.log("that.inpLikedate:", that.inpLikedate);

          // that.inpLikedate[0].value = that.memberdata["like0"];
          // that.inpLikedate[1].value = that.memberdata["like1"];
          // that.inpLikedate[2].value = that.memberdata["like2"];
          // that.inpLikedate[3].value = that.memberdata["like3"];
          // that.inpLikedate[4].value = that.memberdata["like4"];
          // that.inpLikedate[5].value = that.memberdata["like5"];
          // that.inpLikedate[6].value = that.memberdata["like6"];

          // that.inpLikedate[0].other = that.memberdata["other0"];
          // that.inpLikedate[1].other = that.memberdata["other1"];
          // that.inpLikedate[2].other = that.memberdata["other2"];
          // that.inpLikedate[3].other = that.memberdata["other3"];
          // that.inpLikedate[6].other = that.memberdata["other6"];

          for (var i = 0; i < that.inpLikedate.length; i++) {
            let item = that.inpLikedate[i].values;
            for (var l = 0; l < item.length; l++) {
              if (
                that.inpLikedate[i].value.indexOf(
                  that.inpLikedate[i].values[l].code
                ) > -1
              ) {
                that.inpLikedate[i].values[l].selected = true;
              }
            }
          }

          let statesvalues = that.states.filter((item) => {
            return (
              parseInt(item["country_id"]) ==
              parseInt(that.inpbasedate[3].value)
            );
          });
          // console.log("statesvalues:", statesvalues);
          that.inpbasedate[4].values = statesvalues;

          that.memberdata = global.getMemberinfo();

          that.imgurl = that.memberdata.member_photo;

          that.setinpbasedate();

          if (this.memberdata.isEmpty > 0) {
            const h = this.$createElement;
            this.$info({
              title: "注意",
              content: h("div", {}, [h("p", "请完善您的个人数据！")]),
              onOk() {},
            });
          }
        }
      });
    },

    onBirthdayChange(date, dateString) {
      const that = this;
      console.log(date, dateString);
      that.inpbasedate[2].value = dateString;
    },
    handleSelectChange() {
      console.log("inpbasedate:", this.inpbasedate);
    },
    handleCountrySelectChange(index) {
      let statesvalues = this.states.filter((item) => {
        return (
          parseInt(item["country_id"]) ==
          parseInt(this.inpbasedate[index].value)
        );
      });
      console.log("statesvalues:", statesvalues);
      this.inpbasedate[index + 1].values = statesvalues;
      this.inpbasedate[index + 1].value = "";
    },

    beforeImgUpload(file) {
      const that = this;
      const { form } = this;
      that.uploading = true;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        that.$message.error("你只能上传jpeg或者png文件!");
      }
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        that.$message.error("图片必须要小于 4MB!");
        that.uploading = false;
        return false;
      }
      getBase64(file, (imageUrl) => {
        that.imageUrl = "";

        that.uploading = false;
        console.log("this.imageUrl:", this.imageUrl);
        // can use data-binding to set

        uploadImageP({
          img: imageUrl,
          filename: file.name,
          kind: "personal",
        }).then((res) => {
          console.log(res);
          let results = res.results;
          if (results.message == "1") {
            that.$message.success("上传成功");
            that.imgurl = results.url;
          } else if (results.message == "0") {
            that.$message.wrong("上传失败");
          }
        });
      });
      return isJpgOrPng && isLt2M;
    },

    submitPinfo() {
      let that = this;
      let params = {
        tel: that.memberdata.member_tel,
        datas: that.inpbasedate,
        photo: that.imgurl,
      };
      console.log("params:", params);
      that.isEmpty = 0;
      const h = this.$createElement;
      that.inpbasedate.map((item) => {
        if (item.value == "" && item.required && !item.del) {
          that.isEmpty++;
        }
      });
      if (that.isEmpty > 0) {
        this.$info({
          title: "注意",
          content: h("div", {}, [h("p", "请完善表单数据！")]),
          onOk() {},
        });
        that.checkshow = 1;
        return false;
      }

      updateMember(params).then((res) => {
        console.log("updateMember:", res);
        that.fetchMemberinfo();

        this.editPinfo = false;
        $(".main").scrollTop("0px");
        $(".topbar").removeClass("uptopbar");
        $(".topbar").addClass("downtopbar");
        this.tbanimate = "down";

        this.$el.addEventListener("scroll", that.handleScroll, true);
        this.$message.success("保存成功!");
      });
    },

    cancelbaoming(out_trade_no) {
      const that = this;
      this.$confirm({
        title: "确认撤销",
        content: "您是否确认撤销报名?",
        class: "confirmDiv",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          if (!that.tuikuaning) {
            that.tuikuaning = true;
            console.log("cancelbaoming", out_trade_no);
            cancelTuikuan({ out_trade_no: out_trade_no }).then((res) => {
              if (res.total == "0") {
                that.fetchClist();
                that.tuikuaning = false;
                return false;
              }
              console.log("overTuikuan:", res);
              if (res.status == "PROCESSING") {
                updateTuikuanStatus({
                  out_trade_no: out_trade_no,
                  status: res.status,
                }).then((res2) => {
                  console.log("updateTuikuanStatus:", res2);
                  that.fetchClist();
                  that.tuikuaning = false;
                });
              } else if (res.status == "WRONG") {
                that.tuikuaning = false;
                const h = this.$createElement;
                this.$info({
                  title: "注意",
                  content: h("div", {}, [
                    h("p", `退款处理中，请刷新页面确认状态!`),
                  ]),
                  onOk() {},
                });
              }
            });
          }
        },
      });
    },
    submitLinfo() {
      let that = this;

      that.isEmpty = 0;
      that.inpLikedate.map((item) => {
        if (item.value == "" && item.required && !item.del) {
          that.isEmpty++;
        }
      });

      console.log("that.isEmpty:", that.isEmpty);

      if (that.isEmpty > 0) {
        const h = this.$createElement;
        this.$info({
          title: "注意",
          content: h("div", {}, [h("p", "请完善表单数据！")]),
          onOk() {},
        });
        that.checkshow = 1;
        return false;
      }

      let params = {
        tel: that.memberdata.member_tel,
        datas: that.inpLikedate,
      };
      console.log("params:", params);
      updateMember(params).then((res) => {
        console.log("updateMember:", res);
        that.fetchMemberinfo();

        this.editLinfo = false;
        $(".main").scrollTop("0px");
        $(".topbar").removeClass("uptopbar");
        $(".topbar").addClass("downtopbar");
        this.tbanimate = "down";

        this.$message.success("保存成功!");
      });
    },

    clearpinfo() {
      global.clearMemberInfo();

      memberLogout({}).then((res) => {
        // this.$router.go(-1);
        console.log("memberLogout:", res);
        window.location.href = `#/index`;
      });
    },
    openeditpinfo() {
      window.removeEventListener("scroll", this.handleScroll, true);
      this.editPinfo = true;
    },
    openeditlinfo() {
      window.removeEventListener("scroll", this.handleScroll, true);
      this.editLinfo = true;
    },
    hideqrcode() {
      this.showqr = 0;
    },
    getShowQr(item, kind, id) {
      let that = this;
      console.log(
        this.memberdata.member_tel,
        this.memberdata.member_id,
        kind,
        id
      );

      this.qrLink =
        this.memberdata.member_tel +
        "_" +
        this.memberdata.member_id +
        "_" +
        kind +
        "_" +
        id;

      this.ticketdata = item;

      setTimeout(() => {
        that.showqr = 1;
      }, 300);
    },
    showtabdiv(key) {
      this.tabselect = key;
    },
    fetchone(params = { id: this.id }) {
      let that = this;
      // this.loading = true;

      fetchCaseOne(params).then((res) => {
        console.log("fetchCaseOne:", res);
        // that.loading = false;
        if (res.results.list) {
          that.data = res.results.list[0];
        }
        console.log("data:", that.data);
      });
    },

    sbselect(index) {
      this.sbindex = index;
    },
    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        if (
          this.winHeight >
          this.pageHeight +
            this.pageHeight +
            this.pageHeight +
            group2h +
            group4h +
            group6h +
            2000 +
            2000
        ) {
          console.log("down");
          this.tbanimate = "up";
          // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
          $(".topbar").removeClass("downtopbar");
          $(".topbar").addClass("uptopbar");
        }

        if (winHeight > 120) {
          if (this.winHeight > winHeight && this.tbanimate != "down") {
            this.tbanimate = "down";
            // $(".topbar").stop(true).animate({ top: "0px" }, 100);
            $(".topbar").removeClass("uptopbar");
            $(".topbar").addClass("downtopbar");
          } else if (this.winHeight < winHeight && this.tbanimate != "up") {
            this.tbanimate = "up";
            // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
            $(".topbar").removeClass("downtopbar");
            $(".topbar").addClass("uptopbar");
          }
        }

        this.winHeight = winHeight;
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      // this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        // that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
        this.$router.replace(`/pcenterm/${this.lag}`);
        // window.location.href=`#/pcenterm/${this.lag}`;
        // setTimeout(() => {
        //   window.location.reload();
        // }, 100);
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/index/${this.lag}`;
      }
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });

      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
        console.log(that.w750);
      } else {
        console.log(that.w750);
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });
      global.setIndexDclass("");
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexl.css" />
<style src="../assets/css/indexl1280.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
